import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql, Link } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import { strapiUrl } from '../../../../templates/constants';
import styles from './styles.module.sass';

export default ({ _id, metadata, title, createdAt, banner, thumbnail, indexable }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholder: imageSharp (fluid: {
          originalName: { eq: "Blog-Header.jpg" }
        }) {
          fluid(maxHeight: 490, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ placeholder }) => (
      <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}
        className={styles.wrapper} alignItems='center' alignContent='center' justify='center'>
        <Grid container className={styles.container}>
          <Link className={clsx(
              styles.readMore,
              styles.imageContainer
            )}
            to={indexable
            ? `/blog${metadata.url}`
            : `/blog/article?ref=${_id}`}>
            {thumbnail || banner
            ? <img alt={`blog-post-${title}`}
                src={`${strapiUrl}${(thumbnail && thumbnail.url)
                  || (banner && banner.url)}`}
                className={styles.image}/>
            : <Img imgStyle={{ height: '100%'}}
                className={styles.imageContainer}
                objectFit='cover'
                fluid={placeholder.fluid}/>}
          </Link>
          <Grid container direction='column' className={styles.footerContainer} justify='space-between' alignContent='space-between'>
            <Typography variant='subtitle2' weight='bold' color='highlight' align='left'
              className={styles.thumbnailText}>
              {new Date(createdAt).toLocaleString('da', { dateStyle: 'long'})}
            </Typography>
            <Typography variant='h5' weight='bold' color='dark' align='left'
              className={clsx(
                styles.thumbnailText,
                styles.title
              )}>
              {title}
            </Typography>
            <Link className={styles.readMore}
              to={indexable
              ? `/blog${metadata.url}`
              : `/blog/article?ref=${_id}`}>
              <Typography variant='subtitle2' color='secondaryDark' align='left'
                className={styles.readMoreText}>
                {i18n('Read more')}
              </Typography> 
            </Link>
          </Grid>
        </Grid>
      </Grid>
    )}
  />
)